import { createI18n } from 'vue-i18n';
import enAU from './locales/en-AU.json';

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en-AU',
  fallbackLocale: 'en-AU',
  messages: {
    ['en-AU']: enAU
  }
});
