import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalizedLoaded,
  RouteRecordRaw
} from 'vue-router';
import { beforeEachGuard } from './middleware';
import { store } from '@/store';
import { SET_FLW_ENTRY } from '@/store/actions.type';
import { IS_FLW_ENTRY, CDR_CONFIG } from './query-params.type';
import omit from 'lodash-es/omit';

export const removeGlobalQueryParams = async (to: RouteLocationNormalizedLoaded) => {
  if (to.query.isFlwEntry || to.query.cdr_config) {
    if (to.query.isFlwEntry) {
      await store.dispatch(SET_FLW_ENTRY, JSON.parse((to.query?.isFlwEntry || 'false') as string));
    }
    if (to.query.cdr_config) {
      sessionStorage.setItem(CDR_CONFIG, to.query.cdr_config as string);
    }

    return { path: to.path, query: omit(to.query, IS_FLW_ENTRY, CDR_CONFIG) };
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'LoginPage',
    props: true,
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/LoginPage.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/login/otp',
    name: 'LoginOtpPage',
    props: true,
    component: () => import(/* webpackChunkName: "login-otp" */ '@/views/login/LoginOtpPage.vue'),
    meta: {
      title: 'Request OTP'
    }
  },
  {
    path: '/verify/otp',
    name: 'VerifyOtpPage',
    props: true,
    component: () => import(/* webpackChunkName: "verify-otp" */ '@/views/login/VerifyOtpPage.vue'),
    meta: {
      title: 'Verify OTP'
    }
  },
  {
    path: '/home',
    name: 'DashboardPage',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/views/DashboardPage.vue'),
    meta: {
      title: 'Dashboard',
      auth: true
    },
    beforeEnter: [removeGlobalQueryParams]
  },
  {
    path: '/contact',
    name: 'ContactUsPage',
    component: () => import(/* webpackChunkName: "contact" */ '@/views/ContactUsPage.vue'),
    meta: {
      title: 'Contact Us',
      auth: true
    }
  },
  {
    path: '/about',
    name: 'AboutUsPage',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutUsPage.vue'),
    meta: {
      title: 'About Us',
      auth: true
    }
  },
  {
    path: '/view',
    name: 'ViewPage',
    component: () => import(/* webpackChunkName: "view" */ '@/views/ViewPage.vue'),
    meta: {
      title: 'View',
      auth: true
    }
  },
  {
    path: '/generate',
    name: 'GeneratePage',
    component: () => import(/* webpackChunkName: "generate" */ '@/views/GeneratePage.vue'),
    meta: {
      title: 'Generate',
      auth: true
    }
  },
  {
    path: '/set-password',
    name: 'SetPasswordPage',
    component: () => import(/* webpackChunkName: "set-password" */ '@/views/SetPasswordPage.vue'),
    props: true,
    meta: {
      title: 'Set Password',
      auth: true
    }
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordPage',
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/reset-password/ResetPasswordPage.vue'),
    props: true,
    meta: {
      title: 'Reset Password'
    }
  },
  {
    path: '/resend-reset-password',
    name: 'ResendResetPasswordPage',
    component: () =>
      import(/* webpackChunkName: "resend-reset-password" */ '@/views/reset-password/ResendResetPasswordPage.vue'),
    props: true,
    meta: {
      title: 'Resend Reset Password'
    }
  },
  {
    path: '/register',
    name: 'RegisterPage',
    component: () => import(/* webpackChunkName: "register" */ '@/views/RegisterPage.vue'),
    meta: {
      title: 'Register'
    }
  },
  {
    path: '/consent',
    name: 'ConsentRedirect',
    component: () => import(/* webpackChunkName: "consent" */ '@/views/ConsentLinkRedirect.vue'),
    props: true,
    meta: {
      auth: true
    }
  },
  {
    path: '/insights',
    name: 'InsightsPage',
    component: () => import(/* webpackChunkName: "insights" */ '@/views/InsightsPage.vue'),
    meta: {
      title: 'Insights',
      auth: true
    }
  },
  {
    path: '/:pathMatch(.*)*', // This represents a catch all, the path will be stored in `$route.params.pathMatch`
    name: 'PageNotFound',
    component: () => import(/* webpackChunkName: "page-not-found" */ '@/views/PageNotFound.vue'),
    meta: {
      title: 'Not Found'
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(
  (routeTo: RouteLocationNormalizedLoaded, routeFrom: RouteLocationNormalizedLoaded, next: NavigationGuardNext) =>
    beforeEachGuard(routeTo, routeFrom, next)
);

export default router;
