import { useCurrentEnvConfig } from '@/composables/current-env-tenant-config';
import { store } from '@/store';
import mixpanel, { OverridedMixpanel } from 'mixpanel-browser';

export type AnalyticsClient = OverridedMixpanel | undefined;

declare global {
  // eslint-disable-next-line no-var
  var FrolloMixpanelClient: AnalyticsClient;
}

export const initAnalytics = (): AnalyticsClient => {
  useCurrentEnvConfig();
  const apiToken =
    store.state.currentTenantEnv === 'production'
      ? store.state.currentTenantConfig.tokens.analyticsApiTokenProd
      : store.state.currentTenantConfig.tokens.analyticsApiToken;
  if (apiToken && process.env.NODE_ENV === 'production') {
    mixpanel.init(apiToken, { ignore_dnt: true });
    globalThis.FrolloMixpanelClient = mixpanel;
    return mixpanel;
  }
  return;
};
