
import { defineComponent, onMounted } from 'vue';

export default defineComponent({
  name: 'MainSection',
  emits: ['mounted'],
  setup(_props, ctx) {
    onMounted(() => {
      ctx.emit('mounted');
    });
  }
});
