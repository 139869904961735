import { store } from '@/store';

export const checkRoutes = () => {
  const isRouteEnabled = (routeName: string): boolean => {
    const { currentTenantConfig } = store.state;
    return !currentTenantConfig.features?.disabledPages?.includes(routeName);
  };

  return {
    isRouteEnabled
  };
};
