import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { i18n } from './i18n/i18n';
import '@/styles/main.scss';
import attachSdkinterceptors from './services/api/interceptors';
import { RollbarClient, LogArgument } from '@/clients/rollbar.client';
import { FwButton, FwCard, FwNavigationMenu, FwForm, FwInput, FwCheckbox, FwTable } from '@frollo/frollo-web-ui';
import { store } from './store';

const app = createApp({
  ...App,
  mounted: () => attachSdkinterceptors()
});

app.config.errorHandler = (err) => {
  RollbarClient.error(err as LogArgument);
  throw err;
};

app
  .use(store)
  .use(router)
  .use(i18n)
  .provide('rollbar', RollbarClient)
  // Import and register components globally (register thee types in global-components.d.ts)
  .component('FwButton', FwButton)
  .component('FwNavigationMenu', FwNavigationMenu)
  .component('FwCard', FwCard)
  .component('FwForm', FwForm)
  .component('FwInput', FwInput)
  .component('FwCheckbox', FwCheckbox)
  .component('FwTable', FwTable)
  .mount('#app');
