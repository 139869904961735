import { ACCESS_TOKEN } from '@/router/query-params.type';
import { store } from '@/store';
import { useCurrentEnvConfig } from '@/composables/current-env-tenant-config';
import { FrolloSDK } from '@frollo/frollo-web-sdk';
import { AxiosResponse, AxiosError } from 'axios';

const attachSdkInterceptors = (): {
  interceptorConfig: number;
  interceptorErrors: number;
} => {
  /**
   * Initialise the SDK with the current tenant env config api hostUrl
   */
  const { currentEnvConfig } = useCurrentEnvConfig();
  const currentHost = window.location.host;
  const apiHostUrl =
    currentHost === currentEnvConfig.secondaryHostedDomain
      ? currentEnvConfig.api.secondaryHostUrl
      : currentEnvConfig.api.hostUrl;
  FrolloSDK().init({
    baseUrl: `${apiHostUrl}/api/v2`,
    xApiVersion: '2.27',
    xBundleId: `FPW V${process.env.VUE_APP_VERSION}`,
    xSoftwareVersion: process.env.VUE_APP_VERSION
  });

  const interceptorConfig = globalThis.FrolloClient.interceptors.request.use(
    (config) => {
      if (store.state.currentTenantConfig.features?.customDomain && localStorage.getItem(ACCESS_TOKEN)) {
        config.headers.Authorization = `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`;
      }
      return config;
    },
    (error: AxiosError) => Promise.reject(error)
  );

  const interceptorErrors = globalThis.FrolloClient.interceptors.response.use((response: AxiosResponse) => response);

  return { interceptorConfig, interceptorErrors };
};

export default attachSdkInterceptors;
