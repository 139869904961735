import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "flex items-center justify-left" }
const _hoisted_2 = { class: "text-body font-normal text-base whitespace-normal break-all" }
const _hoisted_3 = { class: "relative" }
const _hoisted_4 = {
  key: 0,
  class: "download-button--tooltip"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_DownloadSvg = _resolveComponent("DownloadSvg")!
  const _component_InfoCircleSvg = _resolveComponent("InfoCircleSvg")!
  const _component_FwButton = _resolveComponent("FwButton")!

  return (_openBlock(), _createBlock(_component_FwButton, { class: "download-button" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
              key: 0,
              class: "inline-flex min-w-5 w-5 h-5 mr-4"
            }))
          : (_openBlock(), _createBlock(_component_DownloadSvg, {
              key: 1,
              class: "text-body min-w-min w-5 h-5 mr-4"
            })),
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.label), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_InfoCircleSvg, {
            class: "text-link w-4 h-4 ml-2",
            onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isHovering = true)),
            onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isHovering = false))
          }),
          _createVNode(_Transition, { name: "fadeIn" }, {
            default: _withCtx(() => [
              (_ctx.isHovering)
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.tooltipLabel), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}