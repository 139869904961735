
import { defineComponent, ref, Transition } from 'vue';
import { DownloadSvg, InfoCircleSvg } from '@frollo/frollo-web-ui/icons';
import LoadingSpinner from './loader/LoadingSpinner.vue';

export default defineComponent({
  name: 'DownloadButton',
  components: { DownloadSvg, InfoCircleSvg, LoadingSpinner, Transition },
  props: {
    label: {
      type: String
    },
    loading: {
      type: Boolean,
      default: false
    },
    tooltipLabel: {
      type: String
    }
  },
  setup() {
    const isHovering = ref(false);

    return {
      isHovering
    };
  }
});
