import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  id: "footer-legal",
  class: "w-full text-sm text-center md:text-right text-grey-base inline-block absolute bottom-4"
}
const _hoisted_2 = { class: "px-4 z-10 relative font-medium flex justify-center md:justify-end space-x-8" }
const _hoisted_3 = ["href"]
const _hoisted_4 = ["href"]
const _hoisted_5 = {
  key: 0,
  class: "relative lg:absolute lg:bottom-0 left-0 text-center w-full px-4 font-medium flex justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_BaseLayout = _resolveComponent("BaseLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseLayout, {
      class: _normalizeClass(["min-h-screen", _ctx.isLegalFooterVisible ? 'pb-12' : ''])
    }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }, 8, ["class"]),
    (_ctx.isLegalFooterVisible)
      ? (_openBlock(), _createElementBlock("footer", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_ctx.tenantConfig.features?.legal?.privacyLink)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  class: "text-grey-base no-underline hover:underline focus:underline",
                  href: _ctx.tenantConfig.features.legal.privacyLink,
                  target: "_blank"
                }, " Privacy Policy ", 8, _hoisted_3))
              : _createCommentVNode("", true),
            (_ctx.tenantConfig.features?.legal?.termsLink)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  class: "text-grey-base no-underline hover:underline focus:underline",
                  href: _ctx.tenantConfig.features.legal?.termsLink,
                  target: "_blank"
                }, " Terms of Service ", 8, _hoisted_4))
              : _createCommentVNode("", true)
          ]),
          (_ctx.tenantConfig.features?.poweredByFrollo)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, " © Frollo Australia Pty Ltd. All rights reserved. "))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}