export const AUTHORISATION_CODE = 'authorisation_code';
export const FLOW_TYPES = 'types';
export const CONTEXT = 'context';
export const REDIRECT_URL = 'redirect_url';
export const EXTERNAL_PARTY_ID = 'external_party_id';
export const DISCLOSURE_FLOW = 'disclosure_flow';
export const ENTRY_APP = 'entry_app';
export const IS_FLW_ENTRY = 'isFlwEntry';
export const ACCESS_TOKEN = 'accessToken';
export const CDR_CONFIG = 'cdr_config';
