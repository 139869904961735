import { OtpTypes } from '@frollo/frollo-web-sdk';

export type ThemeSet = {
  colorPrimary: string;
  colorSecondary: string;
  colorTertiary: string;
  colorLink?: string;
  colorBody?: string;
  fontFamily: string;
  fontUrl?: string;
  colorButtonPrimary?: string;
  colorButtonPrimaryText?: string;
  colorButtonPrimaryFade?: string;
  colorButtonSecondary?: string;
  colorButtonSecondaryText?: string;
  colorButtonTertiary?: string;
  colorButtonTertiaryText?: string;
  buttonFade?: boolean;
};

export interface Features {
  /** List of disabled page route names */
  disabledPages?: string[];
  /** Whether to show the left hand side banner */
  showSidebar?: boolean;
  /** Whether the tenant has a custom domain set for FPW */
  customDomain?: boolean;
  /** The TypeForm form ID */
  surveyFormId?: string;
  /** Whether to enable downloading bank statements on the View page */
  enabledBankStatementDownload?: boolean;
  /** The default chart colours on the insights page */
  chartColours?: string[];
  /** The legal pages links i.e Privacy Policy, Terms of Service */
  legal?: {
    privacyLink?: string;
    termsLink?: string;
  };
  poweredByFrollo?: boolean;
  /** Custom support link */
  support?: {
    name: string;
    url: string;
  };
  /** Enable/disable check for user valid password */
  checkValidPassword?: boolean;
  /** Otp login type */
  otpType: OtpTypes.EMAIL | OtpTypes.SMS;
  /** Unauthenticated users default route names */
  tokenExpiredRoute: string[];
  disableRegistrationLastName?: boolean;
}

export enum EnvConfigEnum {
  sandbox = 'sandbox',
  staging = 'staging',
  uat = 'uat',
  sit = 'sit',
  production = 'production'
}

export interface EnvConfig {
  api: ApiSet;
  hostedDomain: string;
  secondaryHostedDomain?: string;
  oauth: {
    clientId: string;
    domain: string;
  };
  frolloLink: string;
  secondaryFrolloLink?: string;
  cdrConfigExternalId: string;
}

export interface ApiSet {
  hostUrl: string;
  secondaryHostUrl?: string;
}
export interface TenantConfig {
  name: string;
  theme: ThemeSet;
  features: Features;
  tokens: {
    analyticsApiToken: string;
    analyticsApiTokenProd?: string;
  };
  envConfig: Partial<Record<EnvConfigEnum, EnvConfig>>;
}
export interface TenantsConfig {
  [key: string]: TenantConfig;
}

export const tenantsConfig: TenantsConfig = {
  frollo: {
    name: 'Frollo',
    theme: {
      colorPrimary: '#6923ff',
      colorSecondary: '#000',
      colorTertiary: '#fff',
      fontFamily: 'Montserrat',
      colorLink: '#6923ff',
      colorBody: '#000000',
      colorButtonPrimary: '#6923ff',
      colorButtonPrimaryText: '#ffffff',
      colorButtonPrimaryFade: '#854cff',
      colorButtonSecondary: '#ffffff',
      colorButtonSecondaryText: '#000000',
      colorButtonTertiary: '#ffffff',
      colorButtonTertiaryText: '#6923ff'
    },
    features: {
      enabledBankStatementDownload: true,
      otpType: OtpTypes.EMAIL,
      tokenExpiredRoute: ['LoginPage'],
      showSidebar: true,
      disableRegistrationLastName: true
    },
    tokens: {
      analyticsApiToken: '37ea120c4b2a8e64b8aab4020d63b3d3',
      analyticsApiTokenProd: '5ac856c0ae295bc98a6b25555b0adfb8'
    },
    envConfig: {
      sandbox: {
        hostedDomain: 'frollo.sandbox.fp.frollo.com.au',
        secondaryHostedDomain: 'frollo.sandbox.fp.frollo.us',
        oauth: {
          clientId: 'oik8IcwQBAYjV9FD-xZjb5OKfQ5J-Ny1-ljND6_zGGM',
          domain: 'public'
        },
        api: {
          hostUrl: 'https://api-sandbox.frollo.com.au',
          secondaryHostUrl: 'https://api-sandbox.frollo.us'
        },
        frolloLink: 'https://frollo.sandbox.link.frollo.com.au',
        secondaryFrolloLink: 'https://frollo.sandbox.link.frollo.us',
        cdrConfigExternalId: 'frollo-default'
      },
      staging: {
        hostedDomain: 'frollo.staging.fp.frollo.com.au',
        secondaryHostedDomain: 'frollo.staging.fp.frollo.us',
        oauth: {
          clientId: 'oSTuXqmILnP9KuLUd7hw7W0q7Y_VkbHPRexZu1_8mKY',
          domain: 'public'
        },
        api: {
          hostUrl: 'https://api-staging.frollo.com.au',
          secondaryHostUrl: 'https://api-staging.frollo.us'
        },
        frolloLink: 'https://frollo.staging.link.frollo.com.au',
        secondaryFrolloLink: 'https://frollo.staging.link.frollo.us',
        cdrConfigExternalId: 'frollo-default'
      },
      production: {
        hostedDomain: 'app.frollo.com.au',
        secondaryHostedDomain: 'app.frollo.us',
        oauth: {
          clientId: 'ERAY76XNsh1hwNaEWlxxio9PHd8kELoq1PXtFHE-zUc',
          domain: 'public'
        },
        api: {
          hostUrl: 'https://api.frollo.com.au',
          secondaryHostUrl: 'https://api.frollo.us'
        },
        frolloLink: 'https://link.frollo.com.au',
        secondaryFrolloLink: 'https://link.frollo.us',
        cdrConfigExternalId: 'frollo-default'
      }
    }
  },
  nextgen: {
    name: 'Frollo',
    theme: {
      colorPrimary: '#6923ff',
      colorSecondary: '#000',
      colorTertiary: '#fff',
      fontFamily: 'Montserrat',
      colorLink: '#6923ff',
      colorBody: '#000000',
      colorButtonPrimary: '#6923ff',
      colorButtonPrimaryText: '#ffffff',
      colorButtonPrimaryFade: '#854cff',
      colorButtonSecondary: '#ffffff',
      colorButtonSecondaryText: '#000000',
      colorButtonTertiary: '#ffffff',
      colorButtonTertiaryText: '#6923ff'
    },
    features: {
      disabledPages: ['GeneratePage', 'RegisterPage', 'ViewPage', 'InsightsPage'],
      surveyFormId: 'fOFLX6FA',
      enabledBankStatementDownload: true,
      checkValidPassword: false,
      legal: {
        privacyLink: 'https://nextgen.net/#!/privacy',
        termsLink: 'https://nextgen.net/#!/terms'
      },
      poweredByFrollo: true,
      otpType: OtpTypes.EMAIL,
      tokenExpiredRoute: ['LoginOtpPage']
    },
    tokens: {
      analyticsApiToken: '9a4f00841cb2de827d7fdaced37405b1',
      analyticsApiTokenProd: '54ccbc2bc80ded71ce4677971ec5f581'
    },
    envConfig: {
      sandbox: {
        hostedDomain: 'nextgen.sandbox.fp.frollo.us',
        secondaryHostedDomain: 'nextgen.sandbox.fp.frollo.com.au',
        oauth: {
          clientId: 'IORmhpZnIU4wjlc20-PI393ng1m5qI-VzZK8g1iGtuA',
          domain: 'nextgen-sandbox.frollo.us'
        },
        api: {
          hostUrl: 'https://nextgen-sandbox.frollo.us',
          secondaryHostUrl: 'https://nextgen-sandbox.frollo.com.au'
        },
        frolloLink: 'https://nextgen.sandbox.link.frollo.us',
        secondaryFrolloLink: 'https://nextgen.sandbox.link.frollo.com.au',
        cdrConfigExternalId: 'nextgen-default'
      },
      staging: {
        hostedDomain: 'nextgen.staging.fp.frollo.us',
        secondaryHostedDomain: 'nextgen.staging.fp.frollo.com.au',
        oauth: {
          clientId: 'gwiVi2sVIZuDlHjNhNQIkY4jRVGCyqzMkYJiPTG6lzw',
          domain: 'nextgen-staging.frollo.us'
        },
        api: {
          hostUrl: 'https://nextgen-staging.frollo.us',
          secondaryHostUrl: 'https://nextgen-staging.frollo.com.au'
        },
        frolloLink: 'https://nextgen.staging.link.frollo.us',
        secondaryFrolloLink: 'https://nextgen.staging.link.frollo.com.au',
        cdrConfigExternalId: 'nextgen-default'
      },
      production: {
        hostedDomain: 'nextgen.fp.frollo.us',
        secondaryHostedDomain: 'nextgen.fp.frollo.com.au',
        oauth: {
          clientId: 'j-_SJTph-loqVZQqpldy5ZRy7EPGP7tbJo_dPlLCXy4',
          domain: 'nextgen.frollo.us'
        },
        api: {
          hostUrl: 'https://nextgen.frollo.us',
          secondaryHostUrl: 'https://nextgen.frollo.com.au'
        },
        frolloLink: 'https://nextgen.link.frollo.us',
        secondaryFrolloLink: 'https://nextgen.link.frollo.com.au',
        cdrConfigExternalId: 'nextgen-default'
      }
    }
  },
  moneypartner: {
    name: 'Money Partner',
    theme: {
      colorPrimary: '#226169',
      colorSecondary: '#001e41',
      colorTertiary: '#FFFFFF',
      fontFamily: 'Open Sans',
      colorLink: '#226169',
      colorBody: '#000000',
      colorButtonPrimary: '#226169',
      colorButtonPrimaryText: '#ffffff',
      colorButtonPrimaryFade: '#4e8187',
      colorButtonSecondary: '#ffffff',
      colorButtonSecondaryText: '#000000',
      colorButtonTertiary: '#ffffff',
      colorButtonTertiaryText: '#226169'
    },
    features: {
      disabledPages: ['GeneratePage', 'AboutUsPage', 'ViewPage'],
      enabledBankStatementDownload: true,
      chartColours: ['#00855F', '#D86A44', 'rgba(216, 106, 68, 0.4)'],
      otpType: OtpTypes.EMAIL,
      tokenExpiredRoute: ['LoginPage'],
      showSidebar: true
    },
    tokens: {
      analyticsApiToken: 'db9422f63871ff94f97d005e7929cf91',
      analyticsApiTokenProd: '1b27ae7791982e885a41821fe56ff75e'
    },
    envConfig: {
      sandbox: {
        hostedDomain: 'moneypartner.sandbox.fp.frollo.us',
        oauth: {
          clientId: 'rzvirWN4VHx8QOlK2bC1ReTBGD2Pga0ggO2yEzG3tSw',
          domain: 'moneypartner-sandbox.frollo.us'
        },
        api: {
          hostUrl: 'https://moneypartner-sandbox.frollo.us'
        },
        frolloLink: 'https://moneypartner.sandbox.link.frollo.us',
        cdrConfigExternalId: 'moneypartner-default'
      },
      staging: {
        hostedDomain: 'moneypartner.staging.fp.frollo.us',
        oauth: {
          clientId: 'ulAqrosVofD2kN1TF3i8-pXodDwJEwgaealgFNhqKkI',
          domain: 'moneypartner-staging.frollo.us'
        },
        api: {
          hostUrl: 'https://moneypartner-staging.frollo.us'
        },
        frolloLink: 'https://moneypartner.staging.link.frollo.us',
        cdrConfigExternalId: 'moneypartner-default'
      },
      production: {
        hostedDomain: 'moneypartner.fp.frollo.us',
        oauth: {
          clientId: '7xU5hdaOXQiFD8fhWqDJKIX0GK2VUsgDN88UBad2jxM',
          domain: 'moneypartner.frollo.us'
        },
        api: {
          hostUrl: 'https://moneypartner.frollo.us'
        },
        frolloLink: 'https://moneypartner.link.frollo.us',
        cdrConfigExternalId: 'moneypartner-default'
      }
    }
  },
  ampadvice: {
    name: 'AMP',
    theme: {
      colorPrimary: '#226169',
      colorSecondary: '#001e41',
      colorTertiary: '#FFFFFF',
      fontFamily: 'Open Sans',
      colorLink: '#226169',
      colorBody: '#000000',
      colorButtonPrimary: '#226169',
      colorButtonPrimaryText: '#ffffff',
      colorButtonPrimaryFade: '#4e8187',
      colorButtonSecondary: '#ffffff',
      colorButtonSecondaryText: '#000000',
      colorButtonTertiary: '#ffffff',
      colorButtonTertiaryText: '#226169'
    },
    features: {
      disabledPages: ['GeneratePage', 'RegisterPage', 'AboutUsPage'],
      enabledBankStatementDownload: true,
      otpType: OtpTypes.EMAIL,
      tokenExpiredRoute: ['LoginPage']
    },
    tokens: {
      analyticsApiToken: '',
      analyticsApiTokenProd: ''
    },
    envConfig: {
      sandbox: {
        hostedDomain: 'ampadvice.sandbox.fp.frollo.us',
        oauth: {
          clientId: 'CaEDJ4ZQ2W9o_rCh04F3BawOCaNbn0x4Ou6eOmnTAQY',
          domain: 'ampadvice-sandbox.frollo.us'
        },
        api: {
          hostUrl: 'https://ampadvice-sandbox.frollo.us'
        },
        frolloLink: 'https://ampadvice.sandbox.link.frollo.us',
        cdrConfigExternalId: 'ampadvice-default'
      },
      staging: {
        hostedDomain: 'ampadvice.staging.fp.frollo.us',
        oauth: {
          clientId: 'fBNFm-qynhQHHOzZ84P9N-omE2ziSwIGRVS3w8NhPOA',
          domain: 'ampadvice-staging.frollo.us'
        },
        api: {
          hostUrl: 'https://ampadvice-staging.frollo.us'
        },
        frolloLink: 'https://ampadvice.staging.link.frollo.us',
        cdrConfigExternalId: 'ampadvice-default'
      },
      production: {
        hostedDomain: 'ampadvice.fp.frollo.us',
        oauth: {
          clientId: 'Zb3VXcH-K544QXyV9x3kNnKH4yPUELwi5QYGm8zSPhg',
          domain: 'ampadvice.frollo.us'
        },
        api: {
          hostUrl: 'https://ampadvice.frollo.us'
        },
        frolloLink: 'https://ampadvice.link.frollo.us',
        cdrConfigExternalId: 'ampadvice-default'
      }
    }
  },
  pnbank: {
    name: 'P&N',
    theme: {
      colorPrimary: '#E42313',
      colorSecondary: '#333333',
      colorTertiary: '#FFFFFF',
      fontFamily: 'Verdana,Helvetica,Arial',
      fontUrl: 'system-font',
      colorLink: '#E42313',
      colorBody: '#333333',
      colorButtonPrimary: '#E42313',
      colorButtonPrimaryText: '#ffffff',
      colorButtonPrimaryFade: '#9d180d',
      colorButtonSecondary: '#ffffff',
      colorButtonSecondaryText: '#E42313',
      colorButtonTertiary: '#ffffff',
      colorButtonTertiaryText: '#E42313'
    },
    features: {
      disabledPages: ['GeneratePage', 'RegisterPage', 'ViewPage', 'InsightsPage', 'AboutUsPage'],
      otpType: OtpTypes.EMAIL,
      tokenExpiredRoute: ['LoginOtpPage']
    },
    tokens: {
      analyticsApiToken: '',
      analyticsApiTokenProd: ''
    },
    envConfig: {
      sandbox: {
        hostedDomain: 'pnbank.sandbox.fp.frollo.us',
        oauth: {
          clientId: 'rJzQujoyZ91b9Kr3rDOZBuPhZkqYoXIGC5jA-mv_Y2E',
          domain: 'pnbank-sandbox.frollo.us'
        },
        api: {
          hostUrl: 'https://pnbank-sandbox.frollo.us'
        },
        frolloLink: 'https://pnbank.sandbox.link.frollo.us',
        cdrConfigExternalId: 'pnbank-default'
      },
      staging: {
        hostedDomain: 'pnbank.staging.fp.frollo.us',
        oauth: {
          clientId: 'i7QhtEH1GrzRLJ7cpnk4uczliTHqHyPQ0e1ty2AdB1o',
          domain: 'pnbank-staging.frollo.us'
        },
        api: {
          hostUrl: 'https://pnbank-staging.frollo.us'
        },
        frolloLink: 'https://pnbank.staging.link.frollo.us',
        cdrConfigExternalId: 'pnbank-default'
      },
      production: {
        hostedDomain: 'pnbank.fp.frollo.us',
        oauth: {
          clientId: '34WDSlczhY_ru2XCQWNjwrIg4evdLAIML7SLxQ4iBvg',
          domain: 'pnbank.frollo.us'
        },
        api: {
          hostUrl: 'https://pnbank.frollo.us'
        },
        frolloLink: 'https://pnbank.link.frollo.us',
        cdrConfigExternalId: 'pnbank-default'
      }
    }
  }
};
