import { store } from '@/store';
import Rollbar from 'rollbar';
export type { LogArgument } from 'rollbar';

export const RollbarClient = new Rollbar({
  accessToken: '27cf7b59dbe24e079d2b47f3a398867f',
  enabled: process.env.NODE_ENV === 'production',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: store.state.tenant + '-' + store.state.currentTenantEnv,
    tenant: store.state.tenant
  }
});
