import { store } from '@/store';
import { SET_ENV, SET_TENANT } from '@/store/actions.type';
import { EnvConfigEnum, tenantsConfig } from '@/whitelabel.config';

export const useCurrentEnvConfig = () => {
  /** new */
  let currentEnvConfig;
  const currentHost = window.location.host;
  if (currentHost.split('.')[1] == 'local' || currentHost.includes('localhost')) {
    const currentTenant = currentHost.split('.')[1] == 'local' ? currentHost.split('.')[0] : process.env.SERVER_TENANT;
    const currentEnv = process.env.VUE_APP_ENVIRONMENT as EnvConfigEnum;
    store.dispatch(SET_TENANT, currentTenant);
    store.dispatch(SET_ENV, currentEnv);
    const { currentTenantConfig } = store.state;
    currentEnvConfig = currentTenantConfig.envConfig[currentEnv];
  } else {
    for (let i = 0; i < Object.keys(tenantsConfig).length; i++) {
      const currentTenantName = Object.keys(tenantsConfig)[i];
      const currentTenant = tenantsConfig[currentTenantName];
      for (let j = 0; j < Object.keys(currentTenant.envConfig).length; j++) {
        const currentTenantEnv = Object.keys(currentTenant.envConfig)[j] as EnvConfigEnum;
        if (
          currentTenant.envConfig[currentTenantEnv]?.hostedDomain === currentHost ||
          currentTenant.envConfig[currentTenantEnv]?.secondaryHostedDomain === currentHost
        ) {
          store.dispatch(SET_TENANT, currentTenantName);
          store.dispatch(SET_ENV, currentTenantEnv);
          const { currentTenantConfig } = store.state;
          currentEnvConfig = currentTenantConfig.envConfig[currentTenantEnv];
        }
      }
    }
  }

  const { tenant } = store.state;
  if (!currentEnvConfig) {
    throw TypeError(`Environment config for ${tenant} not found`);
  }

  return {
    currentEnvConfig
  };
};
