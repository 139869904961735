import { store } from '@/store';

/**
 *
 * @param fontName Name of the font from google fonts
 */
export const createFontLink = (fontName: string, customUrl = false): void => {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  if (customUrl) {
    link.href = fontName;
  } else {
    link.href = `https://fonts.googleapis.com/css2?family=${fontName}:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap`;
  }
  document.head.appendChild(link);
};

/**
 *  Updates the root CSS variables and font family
 */
export const updateThemeVariables = (): void => {
  const { currentTenantConfig, tenant } = store.state;

  if (!currentTenantConfig?.theme) return;

  const tenantVariables = currentTenantConfig.theme;
  const {
    colorPrimary,
    colorSecondary,
    colorTertiary,
    fontFamily,
    fontUrl,
    colorLink,
    colorBody,
    colorButtonPrimary,
    colorButtonPrimaryText,
    colorButtonPrimaryFade,
    colorButtonSecondary,
    colorButtonSecondaryText,
    colorButtonTertiary,
    colorButtonTertiaryText
  } = tenantVariables;
  // Get the root element
  const rootEl = document.documentElement;

  Object.entries({
    colorPrimary,
    colorSecondary,
    colorTertiary,
    fontFamily,
    colorLink,
    colorBody,
    colorButtonPrimary,
    colorButtonPrimaryText,
    colorButtonPrimaryFade,
    colorButtonSecondary,
    colorButtonSecondaryText,
    colorButtonTertiary,
    colorButtonTertiaryText
  }).forEach(([key, value]) => {
    if (key && value) {
      if (key === 'fontFamily' && fontUrl !== 'system-font') {
        createFontLink(fontUrl ? fontUrl : value, !!fontUrl);
      }
      rootEl.style.setProperty(`--${key}`, value);
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const imagePath = require(`@themes/${tenant}/images/icons/favicon.ico`);
  const favicon = document.getElementById('favicon') as HTMLAnchorElement;
  if (favicon) {
    favicon.href = imagePath.default;
  }
};
