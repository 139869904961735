
import { computed, defineComponent, onMounted, provide, watch } from 'vue';
import { BaseLayout } from '@/components';
import { State } from '@/store';
import { useStore } from 'vuex';
import { updateThemeVariables } from '@/helpers';
import { useCurrentEnvConfig } from './composables/current-env-tenant-config';
import { useRoute } from 'vue-router';
import { initAnalytics } from '@/clients/mixpanel-analytics.client';

export default defineComponent({
  name: 'App',
  components: { BaseLayout },
  setup() {
    const store = useStore<State>();
    const tenantConfig = computed(() => store.state.currentTenantConfig);
    const route = useRoute();

    useCurrentEnvConfig();
    updateThemeVariables();

    const analytics = initAnalytics();
    provide('analytics', analytics);

    watch(
      () => store.state.tenant,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          updateThemeVariables();
        }
      }
    );

    const isLegalFooterVisible = computed(() => {
      if (!route?.meta?.auth) return false;
      const authRequired = route.meta.auth === true;
      return (
        authRequired &&
        !!(
          tenantConfig.value.features?.poweredByFrollo ||
          tenantConfig.value.features?.legal?.privacyLink ||
          tenantConfig.value.features?.legal?.termsLink
        )
      );
    });

    onMounted(() => {
      const appVersion = process.env.VUE_APP_VERSION?.toString();
      console.info(`FPW version: ${appVersion}`);
      analytics?.register({ fpw_version: appVersion });
    });

    return {
      tenantConfig,
      isLegalFooterVisible
    };
  }
});
