import { RouteLocationNormalizedLoaded, RouteRecord, NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { store } from '@/store';
import { ACCESS_TOKEN, EXTERNAL_PARTY_ID } from './query-params.type';
import { checkRoutes } from '@/composables/disabled-routes';
import { SET_CURRENT_CDR_CONFIG, SET_CURRENT_USER, SET_ENTRY_ROUTE, SET_PREVIOUS_ROUTE } from '@/store/actions.type';
import { UserAuthGrantType, UserDetailsResponse, userService } from '@frollo/frollo-web-sdk';

const requiresAuth = (routeLocation: RouteLocationNormalizedLoaded): boolean => {
  return routeLocation.matched.some((route: RouteRecord) => route.meta.auth);
};

const updateMetaTitle = (to: RouteLocationNormalized) => {
  const title = [];

  if (to.meta?.title) {
    title.push(to.meta.title);
  }

  const tenantName = store.state.currentTenantConfig.name;
  title.push(`${tenantName} Financial Passport`);

  window.document.title = title.join(' - ');
};

export const beforeEachGuard = async (
  to: RouteLocationNormalizedLoaded,
  from: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext
) => {
  await store.dispatch(SET_PREVIOUS_ROUTE, from.name || null);

  if (to.query.accessToken) {
    localStorage.setItem(ACCESS_TOKEN, to.query.accessToken as string);
    delete to.query.accessToken;
  }

  const { isRouteEnabled } = checkRoutes();
  if (!isRouteEnabled(to.name as string)) {
    return next({ name: 'DashboardPage' });
  }

  const { tokenExpiredRoute, checkValidPassword, customDomain } = store.state.currentTenantConfig.features;

  const setFirstPasswordCheckValidate = async (userDetails: UserDetailsResponse) => {
    if (checkValidPassword === true && userDetails.valid_password === false && to.name !== 'SetPasswordPage') {
      await store.dispatch(SET_ENTRY_ROUTE, to);
      return next({
        name: 'SetPasswordPage',
        query: to.query[EXTERNAL_PARTY_ID] ? { [EXTERNAL_PARTY_ID]: to.query[EXTERNAL_PARTY_ID] } : {}
      });
    }
  };

  updateMetaTitle(to);

  const { authenticateUser, getUserDetails } = userService();

  let authCodes = to.query?.authorisation_code || null;
  const authCode = Array.isArray(authCodes) ? authCodes[0] : authCodes;

  if (requiresAuth(to)) {
    try {
      if (authCode) {
        delete to.query.authorisation_code;
        authCodes = null;
        const response = await authenticateUser({
          grant_type: UserAuthGrantType.AUTHORISATION_CODE,
          code: authCode
        });
        if (customDomain) {
          localStorage.setItem(ACCESS_TOKEN, response.data.access_token);
        }
      }
      const userDetails: UserDetailsResponse = (await getUserDetails()).data;
      globalThis.FrolloMixpanelClient?.identify(userDetails.id.toString());
      globalThis.FrolloMixpanelClient?.people.set_once({ frollo_user_id: userDetails.id.toString() });
      globalThis.FrolloMixpanelClient?.register({ frollo_user_id: userDetails.id.toString() });
      await store.dispatch(SET_CURRENT_USER, userDetails);
      await store.dispatch(SET_CURRENT_CDR_CONFIG);
      await setFirstPasswordCheckValidate(userDetails);
    } catch (e) {
      if (to.name === 'ConsentRedirect') {
        await store.dispatch(SET_ENTRY_ROUTE, to);
      }
      return next({ name: tokenExpiredRoute[0] });
    }
  }

  return next();
};
