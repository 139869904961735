export const UPDATE_TENANT = 'updateTenant';
export const UPDATE_ENV = 'updateEnvironment';
export const UPDATE_CURRENT_USER = 'updateCurrentUserDetails';
export const UPDATE_CURRENT_CDR_CONFIG = 'updateCurrentCdrConfig';
export const UPDATE_CURRENT_ADR_CONFIG = 'updateCurrentAdrConfig';
export const UPDATE_ENTRY_ROUTE = 'updateEntryRoute';
export const UPDATE_PREVIOUS_ROUTE = 'updatePreviousRoute';
export const UPDATE_FLW_ENTRY = 'updateFlwEntry';
export const UPDATE_RESET_PASSWORD_EMAIL = 'updateResetPasswordEmail';
export const UPDATE_OTP_IDENTIFIER = 'updateOtpIdentifier';
