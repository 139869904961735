import { createStore } from 'vuex';
import {
  UPDATE_CURRENT_USER,
  UPDATE_TENANT,
  UPDATE_CURRENT_CDR_CONFIG,
  UPDATE_CURRENT_ADR_CONFIG,
  UPDATE_ENTRY_ROUTE,
  UPDATE_PREVIOUS_ROUTE,
  UPDATE_FLW_ENTRY,
  UPDATE_ENV,
  UPDATE_RESET_PASSWORD_EMAIL,
  UPDATE_OTP_IDENTIFIER
} from './mutations.type';
import {
  SET_CURRENT_USER,
  SET_TENANT,
  SET_CURRENT_CDR_CONFIG,
  SET_ENTRY_ROUTE,
  SET_PREVIOUS_ROUTE,
  SET_FLW_ENTRY,
  SET_ENV,
  SET_RESET_PASSWORD_EMAIL,
  SET_OTP_IDENTIFIER
} from './actions.type';
import { EnvConfigEnum, TenantConfig, tenantsConfig } from '@/whitelabel.config';
import {
  UserDetailsResponse,
  CdrConfig,
  configService,
  CdrModel,
  RelatedPartyType,
  RelatedParty
} from '@frollo/frollo-web-sdk';
import { RouteLocationNormalizedLoaded } from 'vue-router';
import { useCurrentEnvConfig } from '@/composables/current-env-tenant-config';
import { CDR_CONFIG } from '@/router/query-params.type';

export interface State {
  tenant: string;
  currentTenantConfig: TenantConfig;
  currentTenantEnv: EnvConfigEnum | null;
  currentUserDetails: UserDetailsResponse | null;
  currentCdrConfig: CdrConfig | null;
  currentAdrConfig: RelatedParty | null;
  entryRouteLocation: RouteLocationNormalizedLoaded | null;
  previousRouteName: string | null;
  isFlwEntry: boolean;
  resetPasswordEmail: string;
  otpIdentifier: string;
}

export const store = createStore<State>({
  state() {
    return {
      tenant: 'frollo',
      currentTenantConfig: tenantsConfig.frollo,
      currentTenantEnv: null,
      currentUserDetails: null,
      currentCdrConfig: null,
      currentAdrConfig: null,
      entryRouteLocation: null,
      previousRouteName: null,
      isFlwEntry: false,
      resetPasswordEmail: '',
      otpIdentifier: ''
    };
  },
  mutations: {
    [UPDATE_TENANT](state, value: string) {
      state.tenant = value;
      state.currentTenantConfig = tenantsConfig[value];
    },
    [UPDATE_ENV](state, value: EnvConfigEnum) {
      state.currentTenantEnv = value;
    },
    [UPDATE_CURRENT_USER](state, value: UserDetailsResponse) {
      state.currentUserDetails = value;
    },
    [UPDATE_CURRENT_CDR_CONFIG](state, value: CdrConfig) {
      state.currentCdrConfig = value;
    },
    [UPDATE_CURRENT_ADR_CONFIG](state, value: RelatedParty) {
      state.currentAdrConfig = value;
    },
    [UPDATE_ENTRY_ROUTE](state, value: RouteLocationNormalizedLoaded) {
      state.entryRouteLocation = value;
    },
    [UPDATE_PREVIOUS_ROUTE](state, value: string) {
      state.previousRouteName = value;
    },
    [UPDATE_FLW_ENTRY](state, value: boolean) {
      state.isFlwEntry = value;
    },
    [UPDATE_RESET_PASSWORD_EMAIL](state, value: string) {
      state.resetPasswordEmail = value;
    },
    [UPDATE_OTP_IDENTIFIER](state, value: string) {
      state.otpIdentifier = value;
    }
  },

  actions: {
    [SET_TENANT](context, value: string) {
      context.commit(UPDATE_TENANT, value);
    },
    [SET_ENV](context, value: EnvConfigEnum) {
      context.commit(UPDATE_ENV, value);
    },
    [SET_CURRENT_USER](context, value: UserDetailsResponse) {
      context.commit(UPDATE_CURRENT_USER, value);
    },
    async [SET_CURRENT_CDR_CONFIG](context) {
      const { getCdrConfig } = configService();
      const { currentEnvConfig } = useCurrentEnvConfig();
      const cdrConfigId = sessionStorage.getItem(CDR_CONFIG) || currentEnvConfig?.cdrConfigExternalId;
      const cdrConfig = (await getCdrConfig(cdrConfigId)).data;

      context.commit(UPDATE_CURRENT_CDR_CONFIG, cdrConfig);

      const adrConfig =
        cdrConfig.parties?.find((party) => {
          const cdrModel = cdrConfig.model;
          const partyType = party.type.toString();
          if (cdrModel === CdrModel.principal && partyType === cdrModel) {
            return party;
          }
          if (cdrModel === CdrModel.representative) {
            if (party.type === RelatedPartyType.principal) {
              return party;
            }
          }
        }) || null;
      context.commit(UPDATE_CURRENT_ADR_CONFIG, adrConfig);
    },
    [SET_ENTRY_ROUTE](context, value: RouteLocationNormalizedLoaded) {
      context.commit(UPDATE_ENTRY_ROUTE, value);
    },
    [SET_PREVIOUS_ROUTE](context, value: string) {
      context.commit(UPDATE_PREVIOUS_ROUTE, value);
    },
    [SET_FLW_ENTRY](context, value: boolean) {
      context.commit(UPDATE_FLW_ENTRY, value);
    },
    [SET_RESET_PASSWORD_EMAIL](context, value: string) {
      context.commit(UPDATE_RESET_PASSWORD_EMAIL, value);
    },
    [SET_OTP_IDENTIFIER](context, value: string) {
      context.commit(UPDATE_OTP_IDENTIFIER, value);
    }
  }
});
