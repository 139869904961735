import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "flex" }
const _hoisted_2 = { class: "content-container w-full" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 1,
  class: "header-logo mt-14 mb-10"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 2,
  class: "lg:mx-auto mb-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FwNavigationMenu = _resolveComponent("FwNavigationMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.router.currentRoute.value.name && _ctx.router.currentRoute.value.name !== 'ConsentRedirect')
        ? (_openBlock(), _createElementBlock("header", {
            key: 0,
            class: _normalizeClass(["header-content z-50", { 'sticky top-0 right-0': _ctx.route.name && _ctx.showNav(_ctx.route.name) }])
          }, [
            (_ctx.route.name && _ctx.showNav(_ctx.route.name))
              ? (_openBlock(), _createBlock(_component_FwNavigationMenu, {
                  key: 0,
                  "menu-items": _ctx.menuItems,
                  "action-label": "Logout",
                  onAction: _cache[0] || (_cache[0] = ($event: any) => (_ctx.logoutUser()))
                }, {
                  logo: _withCtx(() => [
                    _createElementVNode("img", {
                      src: _ctx.tenantLogoSrc,
                      class: "h-10 m-auto"
                    }, null, 8, _hoisted_3)
                  ]),
                  _: 1
                }, 8, ["menu-items"]))
              : (_ctx.route.name !== 'RegisterPage')
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("img", {
                      src: _ctx.tenantLogoSrc,
                      class: "h-[3.8rem] m-auto"
                    }, null, 8, _hoisted_5)
                  ]))
                : _createCommentVNode("", true),
            (_ctx.$slots.header)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _renderSlot(_ctx.$slots, "header")
                ]))
              : _createCommentVNode("", true)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["max-w-[900px] mx-auto flex flex-col", { 'my-8': _ctx.route.name && _ctx.showNav(_ctx.route.name), '!max-w-[1000px]': _ctx.route.name == 'RegisterPage' }])
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 2))
        : _createCommentVNode("", true)
    ])
  ]))
}