
import { computed, defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { useStore } from 'vuex';
import { State } from '@/store';
import { createSidetab } from '@typeform/embed';
import '@typeform/embed/build/css/sidetab.css';

export default defineComponent({
  name: 'SurveyPopout',
  setup() {
    const openSideTab = ref<() => void>();
    const destroySideTab = ref<() => void>();
    const store = useStore<State>();

    const emailAddress = ref(store.state.currentUserDetails?.email);

    const trackedFields = computed(() => {
      if (!emailAddress.value) return;
      return { emailaddress: emailAddress.value };
    });

    onMounted(async () => {
      if (store.state.currentTenantConfig.features?.surveyFormId) {
        const { open, unmount } = createSidetab(store.state.currentTenantConfig.features?.surveyFormId, {
          buttonColor: 'var(--colorButtonPrimary)',
          hidden: trackedFields.value
        });

        openSideTab.value = open;
        destroySideTab.value = unmount;
      }
    });

    onUnmounted(() => {
      if (destroySideTab.value) destroySideTab.value();
    });

    return {
      store,
      trackedFields
    };
  }
});
