
import { computed, defineComponent, inject, ref } from 'vue';
import { useStore } from 'vuex';
import { State } from '@/store';
import { RouteRecordName, useRoute, useRouter } from 'vue-router';
import { AnalyticsClient } from '@/clients/mixpanel-analytics.client';
import { userService } from '@frollo/frollo-web-sdk';
import { toastService } from '@frollo/frollo-web-ui';

export default defineComponent({
  name: 'BaseLayout',
  setup() {
    const store = useStore<State>();

    const tenant = computed(() => store.state.tenant);

    const tenantConfig = computed(() => store.state.currentTenantConfig);

    const tenantLogoSrc = computed(() => require(`@themes/${tenant.value}/images/logo.svg?source`));

    const router = useRouter();
    const route = useRoute();

    const { revokeUserAuth } = userService();

    const analytics = inject('analytics') as AnalyticsClient;

    const logoutUser = async () => {
      // Revoke token and route to main page
      try {
        analytics?.track('Click Log Off');
        await revokeUserAuth();
        toastService().success({
          content: 'Successfully logged out',
          timeout: 5000
        });
        router.push({ name: tenantConfig.value.features.tokenExpiredRoute[0] });
      } catch (error) {
        analytics?.track('Logout failed');
        toastService().error({
          content: 'Error logging user out, please try again',
          timeout: 5000
        });
      }
    };

    const pagesWithNav = ref(['DashboardPage', 'ContactUsPage', 'AboutUsPage', 'InsightsPage']);

    const showNav = (routeName?: RouteRecordName | null) => {
      return pagesWithNav.value.includes(routeName as string);
    };

    const menuItems = computed(() => {
      return [
        {
          to: { name: 'DashboardPage' },
          label: 'Home'
        },
        {
          to: { name: 'InsightsPage' },
          label: 'Insights'
        },
        {
          to: { name: 'AboutUsPage' },
          label: 'About'
        },
        {
          to: { name: 'ContactUsPage' },
          label: 'Contact Us'
        }
      ].filter((menuItem) => {
        return !tenantConfig.value.features?.disabledPages?.includes(menuItem.to.name);
      });
    });

    return {
      tenant,
      tenantConfig,
      tenantLogoSrc,
      router,
      route,
      logoutUser,
      pagesWithNav,
      showNav,
      menuItems
    };
  }
});
