import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "hidden"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.store.state.currentTenantConfig.features?.surveyFormId && _ctx.trackedFields)
    ? (_openBlock(), _createElementBlock("span", _hoisted_1))
    : _createCommentVNode("", true)
}